@import "styles/mixins";
// @import url("https://fonts.googleapis.com/css2?family=Archivo+Black&family=Montserrat:wght@900&family=Passion+One:wght@700;900&family=Righteous&display=swap");

.container {
  --max-width: 1200px;
  --padding: 0rem;
  width: min(var(--max-width), 100% - var(--padding) * 2);
  margin-inline: auto;
}

.rowHeader {
  padding-top: 4rem;
  h2 {
    // color: var(--clr-neutral-100);
    text-transform: uppercase;
    font-size: clamp(4rem, 10vw, 220px); // inter
    // font-size:clam(9rem, 20vw, 300px);
    line-height: 105%;
    font-weight: 900;
    text-align: center;
    @include mediumScreen() {
      font-size: 25.5vw;
      text-align: left;
    }
  }
  span {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-position: center-left;
  }
}

.scrollbar {
  background: transparent;
  left: 0px !important;
  bottom: -1.5rem !important;
  div {
    background: var(--clr-secondary-300);
  }
}

.slide {
  background: transparent;
  // color: var(--clr-neutral-150);
  height: auto;
}

.blogSwiper {
  // overflow: visible;
  padding: 1rem 15px 1rem 1rem !important;
  position: relative;
}


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1rem;
  --max-width: 1200px;
  --padding: 0rem;
  width: min(var(--max-width), 100% - var(--padding) * 2);
  margin-inline: auto;
  // color: var(--clr-neutral-100);
  @include tablet() {
    padding-inline: 1em;
  }
  &__nav {
    display: flex;
    align-items: center;
    gap: 0.5em;
    @include tablet() {
      text-align: right;
      display: none;
    }
  }
}

.title {
  h2 {
    font-size: 2.5rem;
    font-weight: 700;
    text-transform: capitalize;
    border-bottom: 2px solid transparent;
    @include tablet() {
      font-size: 2rem;
    }
  }
}


.prev,
.next {
  opacity:1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  &.prev {
    left: 0;
  }

  &.next  {
    right: 0;
  }
  @include largeTablet(){
    display:none;
  }

}

.swiper-pagination {
  position: initial;
  padding-top: 1em;
}

.pagination {
  text-align: center;
  // border-bottom: 1px solid var(--clr-neutral-250);
  padding-bottom: 1.5em;
  position: relative;
  a {
    color: var(--clr-neutral-900);
    font-weight: 500;
    font-size: 0.875rem;
    position: absolute;
    right: 0px;
    top: 0px;
    padding-right: 1em;
    text-transform: capitalize;
    &:hover {
      color: var(--clr-primary-300);
      text-decoration: underline;
    }
    svg {
      font-size: 0.75rem;
      padding-left: 5px;
    }
    @include tablet() {
      display: none;
    }
  }
}
