@import "styles/mixins";

.categorySwiper {
  padding: 1rem 15px 1rem 1rem !important;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  --max-width: 1200px;
  --padding: 1rem;
  width: min(var(--max-width), 100% - var(--padding) * 2);
  margin-inline: auto;

}


.title {
  display: flex;
  align-items: center;
  gap: 10px;
  .icon {
    font-size: 3rem;
    color: var(--clr-primary-300);
  }
  h2 {
    display: flex;
    flex-direction: column;
    font-size: 2.5rem;
    font-weight: 700;
    text-transform: capitalize;
    @include tablet() {
      font-size: 2rem;
    }
  }
}


.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  &.prev {
    left: 0;
  }

  &.next {
    right: 0;
  }
  @include largeTablet(){
    display:none;
  }
}

.pagination {
  text-align: center;
  padding-bottom: 1.5em;
  position: relative;

  a {
    color: var(--clr-neutral-900);
    font-weight: 600;
    font-size: 0.875rem;
    position: absolute;
    right: 0px;
    top: 0px;
    padding-right: 1em;
    text-transform: capitalize;

    svg {
      font-size: 0.75rem;
      padding-left: 5px;
    }
  }
}

.slide a {
  background:var(--clr-neutral-100);
  padding:1.5rem;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:1rem;
  border: 1px solid var(--clr-neutral-250);
  border-radius: var(--border-radius);
  background: var(--clr-neutral-100);
  transition: box-shadow 0.2s ease;
  svg {
    font-size:3.5rem; 
    color:var(--clr-primary-300);
  }
  &:hover svg {
    color:var(--clr-accent-300);
  }
}

.slideTitle {
  font-size:1.5rem;
  font-weight:600;
  text-transform:capitalize;
  white-space: nowrap;
  @include tablet() {
    font-size:1.25rem;
  }
}