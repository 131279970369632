@import "styles/mixins";

.lastSlide {
  a {
  position: relative;
  background: var(--clr-primary-300);
  color: var(--clr-neutral-100);
  display: flex;
  flex-direction: column;
  gap:1rem;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
  border: 1px solid var(--clr-neutral-250);
  transition: box-shadow 0.2s ease;
  align-items: center;
  justify-content: center;
  font-size:1.5rem;
  svg {
    font-size: 2.5rem;
  }
  &:hover {
    background:var(--clr-primary-400);
  }
  }
}

.productSwiper {
  padding:1rem 15px 1rem 1rem;
  display: grid;
}

.container:not(:first-of-type) .header {
  margin-top: 2em;
}

.header {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  row-gap: 0rem;
  justify-content: space-between;
  align-items: center;
  padding-inline: 1em;

  &__content {
    .icon {
      font-size: 3rem;
      color: var(--clr-primary-300);
      @include mobile() {
        font-size: 2.5rem;
      }
    }
  }
  &__nav {
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-left: auto;
    @include tablet() {
      text-align: right;
      display: none;
    }
  }
  @include tablet() {
    padding-left: 0em;
    padding-inline: 0em;
    padding-inline: 1em;
  }
}

.link {
  text-transform: capitalize;
  white-space: nowrap;
  svg {
    font-size: 0.75rem;
  }
}

.title {
  display:flex;
  align-items: center;
  gap:10px;
  h2 {
    display: flex;
    flex-direction: column;
    font-size: 2.5rem;
    font-weight: 700;
    line-height:normal;
    // padding-bottom:0.875rem;
    &::first-letter {
      text-transform: capitalize;
    }
    @include tablet() {
      font-size: 2rem;
    }
  }
}

.subtitle {
  max-width: 860px;
}

.productSwiper:hover {
  .prev, .next {
    opacity: 1;
  }
}

.prev,
.next {
  opacity:1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  &.prev {
    left: 0;
  }

  &.next  {
    right: 0;
  }
  @include largeTablet(){
    display:none;
  }
}

.tabs:hover .tabPrev,
.tabs:hover .tabNext {
  display: flex;
  @include mediumScreen() {
    display: none;
  }
}
.tabPrev,
.tabPrev:hover {
  justify-content: flex-start;
  left: -8px;
  // background: linear-gradient(
  //   -90deg,
  //   rgba(255, 255, 255, 0) 0%,
  //   rgba(255, 255, 255, 1) 90%
  // );
}
.tabNext,
.tabNext:hover {
  justify-content: flex-end;
  right: -8px;
  // background: linear-gradient(
  //   90deg,
  //   rgba(255, 255, 255, 0) 0%,
  //   rgba(255, 255, 255, 1) 90%
  // );
}
.tabNext,
.tabPrev {
  display: none;
  color: var(--clr-neutral-800);
  width: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  padding: 8px 10px;
  align-items: center;
  font-size: 1.5rem;
  z-index: 5555;
  user-select: none;
  &:hover {
    color: var(--clr-accent-300);
    cursor: pointer;
  }
  @include tablet() {
    display: none;
  }
  svg {
    background: white;
    border-radius: 100px;
    padding: 8px;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.swiper-pagination {
  position: initial;
  padding-top: 1em;
}

.pagination {
  text-align: center;
  // border-bottom: 1px solid var(--clr-neutral-250);
  padding-bottom: 1.5em;
  position: relative;
  a {
    color: var(--clr-neutral-900);
    font-weight: 600;
    font-size: 0.875rem;
    position: absolute;
    right: 0px;
    top: 0px;
    padding-right: 1em;
    text-transform: capitalize;
    &:hover {
      color: var(--clr-primary-300);
      text-decoration: underline;
    }
    svg {
      font-size: 0.75rem;
      padding-left: 5px;
    }
    @include tablet() {
      display: none;
    }
  }
}

.shopSlider {
  padding: 1em;
  margin-inline: 0em;
  content-visibility: auto;
  @include tablet() {
    padding: 0.6em 1em;
  }
}

// .slide {
//   // width: 250px;
//   width: 279px!important;
// }

.footer {
  display: flex;
  justify-content: flex-end;
  padding-right: 1em;
  @include tablet() {
    display: none;
  }
  .link {
    color: var(--clr-neutral-900);
    font-weight: 600;
    font-size: 0.875rem;
    padding-top: 0.5em;
    text-transform: capitalize;
    text-decoration: none;
  }
}

.taxIncluded {
  display: block;
  font-size: 0.75rem;
  font-style: italic;
  color: var(--clr-primary-300);
  // letter-spacing: 0.5px;
}

.active .taxIncluded {
  color: var(--clr-warning);
}

.viewAllSpecial {
  color: var(--clr-accent-300);
}

.viewAllHits {
  color: var(--clr-neutral-650);
}

.ellipsis {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide any overflowed text */
  text-overflow: ellipsis; /* Add ellipsis (...) when text overflows */
  max-width: 200px; /* Set the maximum width before adding ellipsis */
}
