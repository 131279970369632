@import "styles/mixins";

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid var(--clr-neutral-250);
  box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 8px;
  border-radius: var(--border-radius);
  background: var(--clr-neutral-100);
  transition: box-shadow 0.2s ease;
  // margin-bottom:1em;
  overflow: hidden;
  height:100%;
  &:hover .link {
    text-decoration: underline;
  }
}

.horizontal {
  flex-direction: row;
  align-items: center;
  // justify-content: space-around;
  padding: 2rem;
  gap: 2rem;
  // flex-wrap:wrap;
  .title {
    font-size: 1.4rem;
  }
  .contentWrapper {
    flex-basis: 50%;
  }
  .content {
    min-width: 250px;
    padding: unset;
    .body {
      padding-bottom: 0.75rem;
    }
  }
  @include tablet() {
    flex-direction: column;
    .content {
      min-width: unset;
    }
  }
}

.reverse {
  flex-direction: row-reverse;
  @include tablet() {
    flex-direction: column;
  }
}

.image {
  // width: 100%;
  // height: 100%;
  position: relative;
}

.equalHeight {
  img {
    overflow: hidden;
    max-height: 300px;
    min-height: 300px;
    object-fit: cover;
  }
}

.imgTag {
  background: var(--clr-warning);
  border-radius: var(--border-radius);
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;
  height: fit-content;
  width: fit-content;
  padding: 1rem;
  // color:white;
}

.contentWrapper {
  flex-basis: 100%;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1em;
  padding: 2em;
  justify-content: center;
  @include tablet() {
    padding: 1em;
  }
}

.body {
  font-size: var(--fs-400);
  // color: #a1a1aa;
  color:var(--clr-neutral-700);
}

.title {
  display: flex;
  gap: 0.5rem;
  font-weight: 600;
  font-size: 1.25rem;
  padding-bottom: 5px;
}

.titleTag {
  font-size: 12px;
  font-weight: 400;
  color: var(--clr-neutral-100);
  padding: 4px 8px;
  background-color: var(--clr-danger);
  border-radius: var(--border-radius-sm);
}

.icon {
  font-size: 2rem;
  color: var(--clr-primary-300);
}

.center {
  justify-content: center;
}

.link {
  font-size: unset;
  display: flex;
  align-items: center;
  gap: 5px;
  svg {
    font-size: 1rem !important;
  }
}


.white {
  .content {
    align-items: flex-start;
    text-align: left;
  }
  .body {
    color:unset;
  }
}


.dark {
  background:var(--clr-neutral-850);
  border-color:var(--clr-neutral-800);
  .title {
    color:var(--clr-neutral-100);
  }
  .body {
    color:#a1a1aa;
  }
  .link {
    color:var(--clr-neutral-200);
  }
}

.contain {
  object-fit: contain;
}