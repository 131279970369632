@import "styles/mixins";

.hero {
  // --max-width: 1400px;
  // width: min(var(--max-width), 100%);
  margin-inline: auto;
  position: relative;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  &__content {
    color: white;
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-width: 650px;
    justify-content: center;
    @include tablet() {
      padding-bottom: 3rem;
      gap: 0.5em;
    }
    @include mobile() {
      padding-bottom: 3rem;
      gap: 0.5em;
    }
    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include tablet() {
        justify-content: flex-end;
      }
    }
  }
  &__title {
    font-weight: var(--fw-semi-bold);
    font-size: 3.75rem;
    line-height: 4rem;
    text-shadow: 1px 1px 15px #00000094;
    font-weight: 800;
    @include tablet() {
      font-size: 3em;
      line-height: 3.5rem;
    }
    @include mobile() {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
  &__body {
    font-size: 1.25rem;
    text-shadow: 1px 1px 5px #000000e1;
    // max-width: 600px;
    font-weight:600;
    line-height: 1.75rem;
    text-wrap:balance;
    @include mobile() {
      font-size: var(--fs-400);
      line-height: 1.5rem;
    }
  }
  button {
    width: fit-content;
  }
  @include largeScreen() {
    padding-top: 0;
  }
}

.overlay {
  display: flex;
  position: relative;
  // background: rgb(0, 0, 0);
  // background: linear-gradient(
  //   90deg,
  //   rgba(0, 0, 0, 0.5) 0%,
  //   rgba(0, 0, 0, 0.1) 75%
  // );
  height: 100%;
  padding: 8rem 4rem;
  @include tablet() {
    padding: 2rem 3rem;
    background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.1) 45%
  );
  }
  @include mobile() {
    padding: 2rem 1rem;
    
  }
}

.swiperSlide {
  height: auto!important;
  min-height:350px;
  @include tablet() {
    height: calc(100svh - 189px)!important;
    max-height:700px;
  }
}

.bullets {
  position: absolute;
  bottom: 0px !important;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  z-index: 999;
  padding: 1rem;
  span {
    background-color: transparent !important;
    width: 0.875rem;
    height: 0.875rem;
    opacity: 1;
    border: 2px solid white;
    filter: drop-shadow(3px 5px 5px rgb(0 0 0 / 0.4));
  }
}

.hero:hover {
  .prev,
  .next {
    opacity: 1;
  }
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 3rem;
  height: 3rem;
  // border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.3;
  transition: opacity 0.5s ease;
  height: 100%;
}
.prev {
  left: 0;
  // background:linear-gradient(-90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
}
.next {
  right: 0;
  // background:linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
}
.prev svg,
.next svg {
  color: white;
  font-size: 2rem;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}
@include mobile() {
  .prev,
  .next {
    display: none;
  }
}

.firstImage {
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0px;
  color: transparent;
  z-index: 1;
  .hero__content {
    z-index: 2;
  }
}